$size: 2vw;
$speed: 30s;

:export {
  speed: $speed;
}

.bouncingWrapper {
  background-color: rgb(0, 0, 0);
  color: #fff;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 50vw;
  overflow: visible;
}

.fall {
  position: relative;
  transform-style: preserve-3d;
  animation: fallRotate $speed * 2.8 infinite linear;

  @keyframes fallRotate {
    to {
      transform: rotateY(360deg);
    }
  }
}
.walls {
  transform-style: preserve-3d;
}

.wall {
  position: absolute;
  width: $size * 4;
  height: $size * 4;
  transform-style: preserve-3d;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.25),
    rgba(182, 182, 182, 0.05)
  );
  animation: move $speed infinite linear;

  &:nth-child(odd) {
    left: $size * -0.5;
    transform: translate(-50%, 50%) rotateY(90deg) rotateX(45deg)
      translatez($size * -1) translateY($size * -11);
  }
  &:nth-child(even) {
    left: $size * 0.5;
    transform: translate(-50%, 50%) rotateY(-90deg) rotateX(45deg)
      translatez($size * -1) translateY($size * -11);
  }

  @for $i from 0 to 10 {
    &:nth-child(#{$i + 1}) {
      animation-delay: $speed / -10 * ($i + 1);
      background-color: hsla(50%, 0%, 100%, 0.15);
    }
  }
  bottom: $size * -21;
  @keyframes move {
    from {
      bottom: $size * -121;
    }
    to {
      bottom: $size * 81;
    }
  }

  & > div {
    position: absolute;
    background-color: inherit;
  }

  .ceil {
    width: $size * 4;
    height: $size * 4;
    background-image: linear-gradient(#fff7, #fff0);
    animation: wallCeil $speed infinite linear;
    animation-delay: inherit;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: radial-gradient(#000, #0000 50%);
      animation: shadow $speed infinite linear;
      animation-delay: inherit;

      @keyframes shadow {
        0%,
        48%,
        53%,
        100% {
          opacity: 0;
          transform: translateY($size * 4) scale(2);
        }
        50% {
          opacity: 0.25;
          transform: translateY($size * 0.2) scale(0.5);
        }
      }
    }
  }

  .top {
    width: $size * 4;
    transform: rotateX(90deg);
    transform-origin: top;
    background-image: linear-gradient(#0007, #fff7);
    animation: wallHeight $speed infinite linear;
    animation-delay: inherit;
  }

  .bottom {
    bottom: 0;
    width: $size * 4;
    transform: rotateX(-90deg);
    transform-origin: bottom;
    background-image: linear-gradient(#fff0, rgba(150, 150, 150, 0.8));
    animation: wallHeight $speed infinite linear;
    animation-delay: inherit;
  }

  .left {
    bottom: 0;
    height: $size * 4;
    transform: rotateY(-90deg);
    transform-origin: left;
    background-image: linear-gradient(
      to bottom left,
      #fff3,
      rgba(150, 150, 150, 0.8)
    );
    animation: wallWidth $speed infinite linear;
    animation-delay: inherit;
  }

  .right {
    bottom: 0;
    right: 0;
    height: $size * 4;
    transform: rotateY(90deg);
    transform-origin: right;
    background-image: linear-gradient(
      to bottom right,
      #fff3,
      rgba(150, 150, 150, 0.8)
    );
    animation: wallWidth $speed infinite linear;
    animation-delay: inherit;
  }

  @keyframes wallCeil {
    0%,
    49.75%,
    55%,
    100% {
      transform: translateZ($size * 1);
    }
    50% {
      transform: translateZ($size * 0.5);
    }
  }

  @keyframes wallHeight {
    0%,
    49.75%,
    55%,
    100% {
      height: $size * 1;
    }
    50% {
      height: $size * 0.5;
    }
  }

  @keyframes wallWidth {
    0%,
    49.75%,
    55%,
    100% {
      width: $size * 1;
    }
    50% {
      width: $size * 0.5;
    }
  }
}

.ballArm {
  position: absolute;
  bottom: $size * -21;
  width: $size;
  height: $size * 12;
  transform-origin: bottom;
  transform-style: preserve-3d;
  animation: armRotate $speed/10 infinite linear alternate;

  @keyframes armRotate {
    from {
      transform: translateX(-50%) rotate(-45deg);
    }
    to {
      transform: translateX(-50%) rotate(45deg);
    }
  }
}

.ball {
  position: absolute;
  width: $size;
  height: $size;
  border-radius: 10%;
  transform-style: preserve-3d;
  animation: ballRotateZ $speed/10 infinite linear alternate;

  @keyframes ballRotateZ {
    from {
      transform: rotate(45deg);
    }
    to {
      transform: rotate(-45deg);
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: $size;
    height: $size;
    background-image: radial-gradient(
      circle at top,
      rgb(255, 255, 255),
      rgb(187, 187, 187)
    );
    border-radius: 50%;
    animation: ballRotateY $speed * 2.8 infinite linear;

    @keyframes ballRotateY {
      from {
        transform: rotateY(0deg);
      }
      to {
        transform: rotateY(-360deg);
      }
    }
  }
}

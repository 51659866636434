.drone {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

html,
body {
  height: 100%;
  background-color: black;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "UberMoveBold";
  src: local("UberMoveBold"), url(./Fonts/UberMoveBold.otf) format("truetype");
}

@font-face {
  font-family: "UberMoveMedium";
  src: local("UberMoveMedium"),
    url(./Fonts/UberMoveMedium.otf) format("truetype");
}
@font-face {
  font-family: "Sacramento-Regular";
  src: local("Sacramento-Regular"),
    url(./Fonts/Sacramento-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  src: local("Poppins-Light"), url(./Fonts/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
    url(./Fonts/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Thin";
  src: local("Poppins-Thin"), url(./Fonts/Poppins-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-ExtraLight";
  src: local("Poppins-ExtraLight"),
    url(./Fonts/Poppins-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: local("Poppins-ExtraBold"),
    url(./Fonts/Poppins-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"), url(./Fonts/Poppins-Bold.ttf) format("truetype");
}

.projRectCard {
  stroke-dasharray: 400%;
  stroke-dashoffset: 400%;
}

.iphone {
  stroke-dasharray: 500%;
  stroke-dashoffset: 500%;
}

.MacBook {
  stroke-dasharray: 500%;
  stroke-dashoffset: 500%;
}

.Subtitle {
  stroke-dasharray: 50%;
  stroke-dashoffset: 0;
}

.Arrow {
  stroke-dasharray: 50%;
  stroke-dashoffset: 0;
}

.RectCard:hover {
  background-color: rgba(255, 255, 255, 0.164);
  fill: rgba(255, 255, 255, 0.205);
  transition: fill 150ms;
}

.GitIcon {
  stroke-dasharray: 200%;
  stroke-dashoffset: 200%;
}

.GitButton:hover .GitIcon {
  fill: rgb(0, 0, 0);
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes dashReverse {
  to {
    stroke-dashoffset: 50%;
  }
}

@keyframes dashReverseGit {
  to {
    stroke-dashoffset: 0;
  }
}

.carousel {
  position: relative;
  width: 80vw;
  z-index: 9999;
}

.carousel-container {
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-item {
  position: relative;
  width: 45vw !important;
  padding-right: 3vw;
  @media (max-width: 768px) {
    width: 42vw;
    margin-top: 1vw;
  }
}

.carousel-button-group {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60%;
  width: 101vw;
}

.button-background {
  position: relative;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.164);
  border-radius: 0.25vw;
  height: 100%;
  width: 4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  @media (max-width: 768px) {
    width: 7vw;
  }
}

.button-background:hover {
  background-color: rgba(255, 255, 255, 0.6);
  transition: background-color 150ms;
}

.button-background:hover .ArrowIcon {
  stroke: black;
  transition: stroke 150ms;
}
